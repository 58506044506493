import React from "react";
import { useApplication } from "@ryerson/frontend.application";
import { ContentSection } from "@ryerson/frontend.layout";
import { Badge } from "@ryerson/frontend.notification";
import { Link } from "@ryerson/frontend.navigation";
import { Breadcrumb } from "@ryerson/frontend.navigation";
import { Typography } from "@ryerson/frontend.typography";
import { Media } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { useStaticQuery, graphql } from "gatsby";
import { Icon } from "@ryerson/frontend.assets";
import { css } from "@emotion/react";
import {
	newsCategories,
	NewsBadgeColor,
	MultiLingualNewsCategories,
	TranslateNewsCategory,
} from "./News";
import StayTunedComponent from "@components/Shared/StayTuned/StayTuned";

export type NewsCategory = {
	label: string;
	url: string;
};

export type NewsLandingStaticContent = {
	title: string;
	backLabel: string;
	backUrl: string;
	loadMore: string;
	categories: NewsCategory[];
};

export type NewsLandingProps = {
	content: NewsLandingStaticContent;
};

const BreadcrumbContainer = styled.div`
	margin-top: 4px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-top: 8px;
			}
		`;
	}}
`;

const NavContainer = styled.div`
	display: block;
	width: 100%;
	height: 58px;
	box-sizing: border-box;
`;

const NavItem = styled.div`
	display: inline-block;
	height: 58px;
	line-height: 57px;
	cursor: pointer;
	width: auto;
`;

const RelativeContentSection = styled(ContentSection)`
	position: relative;
`;

const Divider = styled.div`
	display: block;
	width: 100%;
	height: 1px;
	margin: 0px;
`;

const Spacer = styled.div`
	display: block;
	width: 100%;
	height: 10px;
`;

const NewsItem = styled.div`
	width: calc(25% - 7.5px);
	margin-right: 10px;
	height: 320px;
	display: inline-block;
	margin-bottom: 10px;
	border-radius: 2px;
	vertical-align: top;
	padding: 30px 40px 36px 40px;
	box-sizing: border-box;
	position: relative;
`;

const MobileNewsItem = styled.div`
	width: 100%;
	height: 220px;
	display: block;
	margin-bottom: 20px;
	border-radius: 2px;
	padding: 17px 20px;
	box-sizing: border-box;
	position: relative;
`;

const BigSpacer = styled.div`
	display: block;
	width: 100%;
	height: 70px;
`;

const LoadMore = styled.div`
	display: block;
	margin: 0 auto;
	cursor: pointer;
	width: 210px;
	box-sizing: border-box;
	line-height: 36px;
	text-align: center;
	height: 40px;
	border-radius: 24px;
`;

const RevealBox = styled.div`
	width: calc(100% - 40px);
	position: absolute;
	top: 60px;
	left: 20px;
	height: 0px;
	overflow: hidden;
	padding: 0px 20px;
	box-sizing: border-box;
	box-shadow: 0px 10px 80px rgb(0 0 0 / 7%), 0px 10px 17.869px rgb(0 0 0 / 4%),
		0px 5px 5.32008px rgb(0 0 0 / 3%);
	background-color: #ffffff;
	z-index: 1;
`;

const MobileDivider = styled.hr`
	display: block;
	width: 100%;
	opacity: 0.1;
`;

const CategorySelector = styled.span`
	display: inline-block;
`;

const stayTunedParent = "News-Events";

const Landing: React.FC<NewsLandingProps> = ({ content }) => {
	const {
		localization: { language },
	} = useApplication();
	const queryData = useStaticQuery(graphql`
		query AllNewsArticlesQuery {
			allContentfulNews(sort: { order: DESC, fields: contentful_id }) {
				nodes {
					title
					slug
					publishDate
					category
					node_locale
					contentful_id
				}
			}
		}
	`);
	let recentNews: any = [];
	let previousId: any = null;
	let i = -1;
	queryData.allContentfulNews.nodes.forEach((node: any) => {
		if (previousId !== node.contentful_id) {
			i++;
			recentNews[i] = {};
		}
		recentNews[i][node.node_locale.split("-").shift()] = node;
		recentNews[i].date = node.publishDate;
		recentNews[i].category = node.category;
		for (let x = 0; x < newsCategories.length; x++) {
			if (node.category === newsCategories[x].label) {
				recentNews[i].category = newsCategories[x].url;
				break;
			}
		}
		previousId = node.contentful_id;
	});
	recentNews.sort(function (a: any, b: any) {
		return new Date(b.date).valueOf() - new Date(a.date).valueOf();
	});

	const [currentNews, setCurrentNews] = React.useState(recentNews);
	const [filter, setFilter] = React.useState<string>("");
	const [slicePoint, setSlicePoint] = React.useState<number>(15);
	const [mobileSlicePoint, setMobileSlicePoint] = React.useState<number>(10);
	const [showMobileNav, setShowMobileNav] = React.useState<boolean>(false);
	React.useEffect(() => {
		if (filter === "") {
			setCurrentNews(recentNews);
		} else {
			let newCurrent: any[] = [];
			recentNews.forEach((item: any, index: number) => {
				if (item.category === filter) newCurrent.push(item);
			});
			setSlicePoint(15);
			setMobileSlicePoint(10);
			setCurrentNews(newCurrent);
		}
	}, [filter]);
	const { theme } = useTheme();

	const FormatDate = (date: any) => {
		let dateObject = new Date(date + " 00:00:00");
		let m: any = dateObject.getMonth() + 1;
		let d: any = dateObject.getDate();
		let y: any = dateObject.getFullYear();
		if (m < 10) m = "0" + m;
		if (d < 10) d = "0" + d;
		return m + "/" + d + "/" + y;
	};

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection vPadding="48px" type="tertiary">
					<BreadcrumbContainer theme={theme}>
						<Breadcrumb type="tertiary" size="xs" />
					</BreadcrumbContainer>
					<Typography
						variant="h1"
						css={css`
							margin-top: 20px;
							margin-bottom: 0px;
						`}
						type="tertiary"
					>
						{content.title}
						<Typography
							variant="div"
							css={css`
								display: inline-block;
								margin-left: 40px;
								vertical-align: middle;
							`}
							type="tertiary"
							size="sm"
						>
							<Link to={content.backUrl}>
								<Typography
									variant="div"
									css={css`
										border: 1px solid ${theme.colors.primary.darkGray};
										border-radius: 24px;
										height: 24px;
										width: 24px;
										display: inline-block;
										box-sizing: border-box;
										vertical-align: top;
										text-align: center;
										line-height: 26px;
									`}
								>
									<Icon
										icon="chevron-left"
										color={theme.colors.tertiary.header}
										size="xs"
										css={css`
											cursor: pointer;
										`}
									/>
								</Typography>
								<Typography
									variant="div"
									type="tertiary"
									css={css`
										display: inline-block;
										vertical-align: top;
										margin-left: 13px;
										height: 24px;
										line-height: 24px;
									`}
								>
									{content.backLabel}
								</Typography>
							</Link>
						</Typography>
					</Typography>
				</ContentSection>
				<NavContainer
					css={css`
						background-color: ${theme.colors.secondary.background};
						position: relative;
					`}
				>
					<ContentSection vPadding="0px" type="secondary">
						{content.categories.map((category: NewsCategory, index: number) => {
							return (
								<NavItem
									key={index}
									css={css`
										margin-right: ${index === content.categories.length - 1
											? "0px"
											: "40px"};
										border-bottom: ${filter === category.url
											? "1px solid " + theme.colors.primary.link
											: "1px solid transparent"};
									`}
									onClick={() => {
										setFilter(category.url);
									}}
								>
									<Typography
										variant="span"
										type="secondary"
										color={theme.colors.secondary.header}
										size="md"
										weight="bold"
										css={css`
											display: block;
											height: 57px;
											line-height: 57px;
										`}
									>
										{category.label}
									</Typography>
								</NavItem>
							);
						})}
					</ContentSection>
					<Divider
						css={css`
							border-bottom: 1px solid ${theme.colors.primary.darkerGray};
							opacity: 0.1;
							position: absolute;
							bottom: 0px;
							left: 0px;
						`}
					/>
				</NavContainer>
				<ContentSection type="secondary" vPadding="80px">
					<Spacer />
					<Spacer />
					<Spacer />
					<Spacer />
					{currentNews.slice(0, 3).map((item: any, index: number) => {
						return (
							<NewsItem
								key={index}
								css={css`
									width: ${index === 0
										? "calc( 50% - 5px )"
										: "calc( 25% - 7.5px)"};
									margin-right: ${index === 2 ? "0px" : "10px"};
									background-color: ${theme.colors.primary.background};
								`}
							>
								<Badge
									css={css`
										margin-bottom: 25px;
									`}
									type="primary"
									color={NewsBadgeColor(item[language].category)}
								>
									{
										MultiLingualNewsCategories[language][
											TranslateNewsCategory(item[language].category)
										]
									}
								</Badge>
								<Link
									gatsby
									to={
										"/about-us/news-events/news/" +
										item.category +
										"/" +
										item[language].slug
									}
								>
									<Typography
										variant={index === 0 ? "h3" : "div"}
										weight="bold"
										color={theme.colors.primary.header}
										size="md"
										css={css`
											margin-top: 0px;
											margin-bottom: 0px;
										`}
									>
										{item[language].title}
									</Typography>
								</Link>
								<Typography
									variant="div"
									size="sm"
									type="primary"
									color={theme.colors.primary.gray}
									css={css`
										position: absolute;
										left: 40px;
										bottom: 36px;
									`}
								>
									{FormatDate(item[language].publishDate)}
								</Typography>
							</NewsItem>
						);
					})}
					{currentNews.slice(3, 7).map((item: any, index: number) => {
						return (
							<NewsItem
								key={index}
								css={css`
									margin-right: ${index === 3 ? "0px" : "10px"};
									background-color: ${theme.colors.primary.background};
								`}
							>
								<Badge
									css={css`
										margin-bottom: 25px;
									`}
									type="primary"
									color={NewsBadgeColor(item[language].category)}
								>
									{
										MultiLingualNewsCategories[language][
											TranslateNewsCategory(item[language].category)
										]
									}
								</Badge>
								<Link
									gatsby
									to={
										"/about-us/news-events/news/" +
										item.category +
										"/" +
										item[language].slug
									}
								>
									<Typography
										variant="div"
										weight="bold"
										color={theme.colors.primary.header}
										size="md"
										css={css`
											margin-top: 0px;
											margin-bottom: 0px;
										`}
									>
										{item[language].title}
									</Typography>
								</Link>
								<Typography
									variant="div"
									size="sm"
									type="primary"
									color={theme.colors.primary.gray}
									css={css`
										position: absolute;
										left: 40px;
										bottom: 36px;
									`}
								>
									{FormatDate(item[language].publishDate)}
								</Typography>
							</NewsItem>
						);
					})}
				</ContentSection>
				<StayTunedComponent parent={stayTunedParent} parentCategory={filter} />
				<ContentSection type="secondary" vPadding="80px">
					{currentNews.slice(7, slicePoint).map((item: any, index: number) => {
						return (
							<React.Fragment key={index}>
								<NewsItem
									css={css`
										margin-right: ${(index + 1) % 4 === 0 ? "0px" : "10px"};
										background-color: ${theme.colors.primary.background};
									`}
								>
									<Badge
										css={css`
											margin-bottom: 25px;
										`}
										type="primary"
										color={NewsBadgeColor(item[language].category)}
									>
										{
											MultiLingualNewsCategories[language][
												TranslateNewsCategory(item[language].category)
											]
										}
									</Badge>
									<Link
										to={
											"/about-us/news-events/news/" +
											item.category +
											"/" +
											item[language].slug
										}
										gatsby
									>
										<Typography
											variant="div"
											weight="bold"
											color={theme.colors.primary.header}
											size="md"
											css={css`
												margin-top: 0px;
												margin-bottom: 0px;
											`}
										>
											{item[language].title}
										</Typography>
									</Link>
									<Typography
										variant="div"
										size="sm"
										type="primary"
										color={theme.colors.primary.gray}
										css={css`
											position: absolute;
											left: 40px;
											bottom: 36px;
										`}
									>
										{FormatDate(item[language].publishDate)}
									</Typography>
								</NewsItem>
								{(index + 1) % 8 === 0 ? <BigSpacer /> : <></>}
							</React.Fragment>
						);
					})}
					{currentNews.length - 1 > slicePoint ? (
						<LoadMore
							css={css`
								border: 1px solid ${theme.colors.primary.gray};
							`}
							onClick={() => {
								setSlicePoint(slicePoint + 8);
							}}
						>
							<Typography
								variant="span"
								color={theme.colors.primary.darkGray}
								weight="bold"
								size="sm"
							>
								{content.loadMore}
							</Typography>
						</LoadMore>
					) : (
						<></>
					)}
					<BigSpacer />
					<Spacer />
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection vPadding="20px" type="tertiary">
					<BreadcrumbContainer theme={theme}>
						<Breadcrumb type="tertiary" size="xs" />
					</BreadcrumbContainer>
					<Typography
						variant="h1"
						css={css`
							margin-top: 12px;
							margin-bottom: 0px;
						`}
						type="tertiary"
					>
						{content.title}
					</Typography>
				</ContentSection>
				<RelativeContentSection type="primary" vPadding="0px">
					<Typography
						variant="div"
						size="lg"
						css={css`
							text-align: right;
							height: 60px;
							line-height: 60px;
						`}
						color={theme.colors.primary.link}
					>
						{content.categories.map((category: NewsCategory, index: number) => {
							return (
								<React.Fragment key={index}>
									{category.url === filter ? (
										<CategorySelector
											onClick={() => {
												setShowMobileNav(showMobileNav ? false : true);
											}}
										>
											{category.label}
											<Icon
												icon="arrow-down"
												css={css`
													cursor: pointer;
													margin-left: 8px;
												`}
												size="xs"
												color={theme.colors.primary.link}
											/>
										</CategorySelector>
									) : (
										<></>
									)}
								</React.Fragment>
							);
						})}
					</Typography>
					<RevealBox
						css={css`
							background-color: ${theme.colors.primary.background};
							height: ${showMobileNav === true ? "230px" : "0px"};
							transition: height 0.25s ease;
						`}
					>
						<Spacer
							css={css`
								height: 24px;
							`}
						/>
						{content.categories.map((category: NewsCategory, index: number) => {
							return (
								<React.Fragment key={index}>
									<Typography
										variant="div"
										color={
											filter === category.url
												? theme.colors.primary.gray
												: theme.colors.primary.header
										}
										size="md"
										weight="bold"
										css={css`
											display: block;
											height: 20px;
											line-height: 20px;
											margin-bottom: 10px;
										`}
									>
										<CategorySelector
											onClick={() => {
												setFilter(category.url);
												setShowMobileNav(showMobileNav ? false : true);
											}}
										>
											{category.label}
										</CategorySelector>
									</Typography>
									{index === content.categories.length - 1 ? (
										<></>
									) : (
										<MobileDivider
											css={css`
												color: ${theme.colors.primary.darkerGray};
												margin-bottom: 10px;
											`}
										/>
									)}
								</React.Fragment>
							);
						})}
					</RevealBox>
					{currentNews.slice(0, 6).map((item: any, index: number) => {
						return (
							<MobileNewsItem
								key={index}
								css={css`
									background-color: ${theme.colors.secondary.background};
								`}
							>
								<Badge
									css={css`
										margin-bottom: 25px;
									`}
									type="primary"
									color={NewsBadgeColor(item[language].category)}
								>
									{
										MultiLingualNewsCategories[language][
											TranslateNewsCategory(item[language].category)
										]
									}
								</Badge>
								<Link
									gatsby
									to={
										"/about-us/news-events/news/" +
										item.category +
										"/" +
										item[language].slug
									}
								>
									<Typography
										variant={index === 0 ? "h2" : "div"}
										weight="bold"
										color={theme.colors.primary.header}
										size="lg"
										css={css`
											margin-top: 0px;
											margin-bottom: 0px;
											font-size: ${index === 0 ? "20px" : "inherit"};
											line-height: ${index === 0 ? "24px" : "20px"};
											letter-spacing: ${index === 0 ? "-1px" : "0"};
										`}
									>
										{item[language].title}
									</Typography>
								</Link>
								<Typography
									variant="div"
									size="sm"
									type="primary"
									color={theme.colors.primary.gray}
									css={css`
										position: absolute;
										left: 20px;
										bottom: 17px;
									`}
								>
									{FormatDate(item[language].publishDate)}
								</Typography>
							</MobileNewsItem>
						);
					})}
					<Spacer />
					<Spacer />
				</RelativeContentSection>
				<StayTunedComponent parent={stayTunedParent} parentCategory={filter} />
				<ContentSection type="primary" vPadding="48px">
					{currentNews.slice(6, mobileSlicePoint).map((item: any, index: number) => {
						return (
							<React.Fragment key={index}>
								<MobileNewsItem
									key={index}
									css={css`
										background-color: ${theme.colors.secondary.background};
									`}
								>
									<Badge
										css={css`
											margin-bottom: 25px;
										`}
										type="primary"
										color={NewsBadgeColor(item[language].category)}
									>
										{
											MultiLingualNewsCategories[language][
												TranslateNewsCategory(item[language].category)
											]
										}
									</Badge>
									<Link
										gatsby
										to={
											"/about-us/news-events/news/" +
											item.category +
											"/" +
											item[language].slug
										}
									>
										<Typography
											variant="div"
											weight="bold"
											color={theme.colors.primary.header}
											size="lg"
											css={css`
												margin-top: 0px;
												margin-bottom: 0px;
											`}
										>
											{item[language].title}
										</Typography>
									</Link>
									<Typography
										variant="div"
										size="sm"
										type="primary"
										color={theme.colors.primary.gray}
										css={css`
											position: absolute;
											left: 20px;
											bottom: 17px;
										`}
									>
										{FormatDate(item[language].publishDate)}
									</Typography>
								</MobileNewsItem>
								{(index + 1) % 4 === 0 ? (
									<>
										<Spacer />
										<Spacer />
										<Spacer />
										<Spacer />
									</>
								) : (
									<></>
								)}
							</React.Fragment>
						);
					})}
					{currentNews.length - 1 > mobileSlicePoint ? (
						<LoadMore
							css={css`
								border: 1px solid ${theme.colors.primary.gray};
							`}
							onClick={() => {
								setMobileSlicePoint(mobileSlicePoint + 4);
							}}
						>
							<Typography
								variant="span"
								color={theme.colors.primary.darkGray}
								weight="bold"
								size="sm"
							>
								{content.loadMore}
							</Typography>
						</LoadMore>
					) : (
						<></>
					)}
					<Spacer />
					<Spacer />
					<Spacer />
					<Spacer />
					<Spacer />
					<Spacer />
				</ContentSection>
			</Media>
		</>
	);
};

export default Landing;
